<template>
  <div id="app">
    <router-view></router-view>
    <loading-spinner v-if="isLoading" />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  components: {
    LoadingSpinner
  },
  data() {
    return {
      isLoading: false
    };
  },
  watch: {
    '$route': function () {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  }
};
</script>
