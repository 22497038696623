import Client from "@/Client";

const state = {
  user: null,
  token: null,
  errors: null,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
};

const actions = {
  async login({ commit }, { email, password }) {
    try {
      const response = await Client.post(
        "/api/login",
        { email, password },
        { withCredentials: true, timeout: 10000 }
      );
      if (response.data) {
        commit("setUser", response.data.user);
        commit("setToken", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("subscriptionStarted", true);
        localStorage.setItem(
          "subscriptionEndDate",
          response.data.user.subscriptionEndDate
        );
        return response.data;
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      if (error.response) {
        commit("setErrors", error.response.data);
        throw error.response.data;
      } else {
        throw error;
      }
    }
  },
  async checkAndUpdateTrialStatus({ state }, isNewUser) {
    try {
      const response = await Client.get("/api/trialStatus", {
        headers: { token: state.token },
        timeout: 10000,
      });

      if (response.data.isTrialActive) {
        localStorage.setItem("trialStarted", "true");
        localStorage.setItem("trialEndDate", response.data.trialEndDate);
      }

      return isNewUser ? "/payment" : "/user/dashboard";
    } catch (error) {
      console.error("Error updating trial status:", error);
      throw error;
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
