import Client from "@/Client";

const state = {
  email: "",
  emailErr: "",
};

const mutations = {
  setEmail(state, email) {
    state.email = email;
  },
  setEmailErr(state, error) {
    state.emailErr = error;
  },
};

const actions = {
  async checkEmailExists({ commit }, email) {
    try {
      const response = await Client.get(
        `/api/email?email=${email.toLowerCase()}`
      );
      if (!response.data.exists) {
        commit("setEmailErr", "ไม่พบอีเมลนี้ในระบบ");
        return false;
      } else {
        commit("setEmailErr", "");
        return true;
      }
    } catch (error) {
      commit("setEmailErr", "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      return false;
    }
  },
  async sendEmail({ commit }, email) {
    try {
      const response = await Client.post("/api/send-otp", { email });
      console.log(response);

      commit("setEmail", email); // เซ็ต email ใน state

      return response;
    } catch (error) {
      console.error(error);
      commit("setEmailErr", "ส่งรหัสยืนยันไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      throw error;
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
