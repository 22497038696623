import { createApp } from "vue";
import App from "./App.vue";
import router from "./routers";
import i18n from "./i18n";
import store from './store';
import axios from "axios";
import { GChart } from "vue-google-charts";
import vue3GoogleLogin from "vue3-google-login";
import 'vue3-toastify/dist/index.css';
import Toast from 'vue3-toastify';
// import VueSweetalert2 from "vue-sweetalert2";
import "vue-color-kit/dist/vue-color-kit.css";
import "../src/assets/css/index.css";
import "../src/assets/css/material-symbols.css";
import "../src/css/backgrounds/backgrounds.css";

const client_Id = "661962114161-8uhjg52rb3q9p9rgsq9bie46rnpb4qft.apps.googleusercontent.com";

axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.xsrfHeaderName = "x-xsrf-token";

createApp(App)
  .use(router)
  .component("GChart", GChart)
  // .use(VueSweetalert2)
  .use(Toast)
  .use(store)
  .use(i18n)
  .use(vue3GoogleLogin, { clientId: client_Id })
  .mount("#app");
