import { createStore } from 'vuex';
import login from './login';
import forgetPassword from './forgetPassword';

export default createStore({
  modules: {
    login,
    forgetPassword
  }
});
