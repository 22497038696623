import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import th from './locales/th.json';

const messages = {
  en,
  th
};

const i18n = createI18n({
  locale: 'th', // กำหนดภาษาเริ่มต้น
  fallbackLocale: 'th', // หากไม่มีการแปลจะใช้ภาษาเริ่มต้นนี้
  messages,
});

export default i18n;
