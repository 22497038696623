import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: "/",
        name: "Home",
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user && user.role === "admin") {
                next({ name: "AdminDashboard" });
            } else {
                next({ name: "dashboardUserOne" });
            }
        },
    },
    {
        path: "/orders",
        name: "Orders",
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user && user.role === "user") {
                next({ name: "orderUserOne" });
            }
        },
    },
    {
        path: "/sales",
        name: "Sales",
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user && user.role === "user") {
                next({ name: "salepageUserOne" });
            }
        },
    },
    {
        path: "/products",
        name: "Products",
        beforeEnter: (to, from, next) => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user && user.role === "user") {
                next({ name: "productUserOne" });
            }
        },
    },

    // admin
    { path: "/admindashboard", name: "AdminDashboard", component: () => import("../views/admin/reports/AdminDashboard.vue"), meta: { requiresAuth: true }, },
    { path: "/adminorder", name: "AdminOrder", component: () => import("../views/admin/reports/AdminOrder.vue"), meta: { requiresAuth: true }, },
    { path: '/adminprofile', name: 'AdminProfile', component: () => import('../views/admin/reports/AdminProfile.vue'), meta: { requiresAuth: true } },

    // user

    // login
    { path: '/authen/register', name: 'RegisterOne', component: () => import('../views/profiles/registerOne.vue'), meta: { backgroundColor: 'background-black' }, },
    { path: '/authen/registero', name: 'RegisterTwo', component: () => import('../views/profiles/registerTwo.vue'), meta: { backgroundColor: 'background-black' }, },
    { path: '/authen/registert', name: 'RegisterThree', component: () => import('../views/profiles/registerThree.vue'), meta: { backgroundColor: 'background-black' }, },
    { path: '/authen/login', name: 'LoginOne', component: () => import('../views/profiles/loginEx.vue'), meta: { backgroundColor: 'background-black' }, },
    { path: '/authen/forgetpassword', name: 'forgetPassword', component: () => import('../components/profiles/forgetPassword.vue'), meta: { backgroundColor: 'background-black' }, },
    { path: '/authen/verify-otp/:email', name: 'VerifyOtp', component: () => import('../components/profiles/VerifyOtp.vue'), meta: { backgroundColor: 'background-black' }, },
    { path: '/authen/reset/:email', name: 'ResetPassword', component: () => import('../components/profiles/rePassOne.vue'), meta: { backgroundColor: 'background-black' }, },

    { path: '/user/dashboard', name: 'dashboardUserOne', component: () => import('../views/user/DeshboardUser.vue'), meta: { requiresAuth: true, backgroundColor: 'background-white' }, },
    { path: '/user/order', name: 'orderUserOne', component: () => import('../views/user/OrderUser.vue'), meta: { requiresAuth: true, backgroundColor: 'background-white' }, },
    { path: '/user/product', name: 'productUserOne', component: () => import('../views/user/ProductUser.vue'), meta: { requiresAuth: true, backgroundColor: 'background-white' }, },
    { path: '/user/salepage', name: 'salepageUserOne', component: () => import('../views/user/SalepageUser.vue'), meta: { requiresAuth: true, backgroundColor: 'background-white' }, },
    { path: '/user/salepaget', name: 'salepageUserTwo', component: () => import('../views/user/SalepageUser2.vue'), meta: { requiresAuth: true, backgroundColor: 'background-white' }, },
    { path: '/user/salepageth/:templateId/:salepageId', name: 'salepageUserThree', props: true, component: () => import('../views/user/SalepageUser3.vue'), meta: { requiresAuth: true, backgroundColor: 'background-white' }, },
    { path: '/user/setting', name: 'settingUserOne', component: () => import('../views/user/SettingUser.vue'), meta: { requiresAuth: true, backgroundColor: 'background-white' }, },

    {
        path: '/user/change-template/:salepageId',
        name: 'changeTemplate',
        component: () => import('../components/user/changeTemplate.vue'),
        meta: { requiresAuth: true, backgroundColor: 'background-white' }
    },

    // other
    { path: '/:catchAll(.*)', name: 'PageNotFound', component: () => import('../views/PageS404.vue') },
    { path: '/payment', name: 'paymentUser', component: () => import('../components/user/PaymentUser.vue') },
    { path: '/domaincheck', name: 'DomainCheck', component: () => import('../components/user/DomainCheck.vue') },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    let user = null;
    const token = localStorage.getItem("token");

    try {
        user = JSON.parse(localStorage.getItem("user") || "null");
    } catch (error) {
        console.error("Error parsing user data:", error);
    }

    // const trialStarted = localStorage.getItem("trialStarted") === "true";
    // const trialEndDate = new Date(localStorage.getItem("trialEndDate") || 0);
    // const isTrialExpired = new Date() > trialEndDate;
    // const subscriptionStarted = localStorage.getItem("subscriptionStarted") === "true";
    const subscriptionEndDate = new Date(localStorage.getItem("subscriptionEndDate") || 0);
    const isSubscriptionExpired = new Date() > subscriptionEndDate;

    if (to.matched.some((record) => record.meta.requiresAuth) && (!user || !token)) {
        next("/authen/login");
    } else if ((to.path === "/authen/login" || to.path === "/authen/register" || to.path === "/authen/registero" || to.path === "/authen/registert") && user) {
        next("/user/dashboard");
    } else if ((to.name === "AdminDashboard" || to.name === "AdminOrder" || to.name === "AdminProfile") && (!user || user.role !== "admin")) {
        next("/homeuser");
    // } else if (user && !trialStarted && user.isNewUser && isTrialExpired && to.name !== 'paymentUser' && user.role === "user") {
        // next("/payment");
    } else if (user && isSubscriptionExpired && to.name !== 'paymentUser' && user.role === "user") {
        next("/payment");
    } else if (to.meta.backgroundColor) {
        document.body.className = '';
        document.body.classList.add(to.meta.backgroundColor);
    } else {
        document.body.className = '';
    }

    next();
});

export default router;
