import axios from "axios";

const Client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default Client;
export const baseURL = process.env.VUE_APP_API_URL;
export const baseURLFront = process.env.VUE_APP_FRONT_URL;
export const OBSBaseURL = process.env.VUE_APP_OBS_BASE_URL;
export const OBSImageStyle = process.env.VUE_APP_OBS_IMAGE_PROCESS_STYLE;
