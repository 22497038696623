<template>
    <div class="full-screen-loader" v-show="isLoading">
        <div class="spinner">
            <div class="dot dot1"></div>
            <div class="dot dot2"></div>
            <div class="dot dot3"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
    data() {
        return {
            isLoading: true
        };
    },
    methods: {
        hideLoader() {
            this.isLoading = false;
        }
    },
    mounted() {
        setTimeout(this.hideLoader, 5000);
    }
};
</script>

<style scoped>
.full-screen-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #24202E;
    margin: 0 5px;
    animation: pulse 1.5s infinite ease-in-out;
}

.dot1 {
    animation-delay: 0s;
}

.dot2 {
    animation-delay: 0.2s;
}

.dot3 {
    animation-delay: 0.4s;
}

@keyframes pulse {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.5);
    }
}
</style>